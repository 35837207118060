import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendingPayoutsComponent } from './pending-payouts/pending-payouts.component';
import { PayoutDetailsComponent } from './payout-details/payout-details.component';
import { Routes, RouterModule } from '@angular/router';
import { HttpsService } from '../services/https.service';
import { HelpersModule } from '../helpers/helpers.module';
import { ApprovePayoutsComponent } from './approve-payouts/approve-payouts.component';
import { ProcessedPayoutsComponent } from './processed-payouts/processed-payouts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { P2pApprovePayoutsComponent } from './p2p-approve-payouts/p2p-approve-payouts.component';
import { P2ppayouttimeslotsService } from '../services/p2ppayouttimeslots.service';
import { PendingP2pPayoutComponent } from './pending-p2p-payout/pending-p2p-payout.component';
import { DirectPayProcessedPayoutComponent } from './direct-pay-processed-payout/direct-pay-processed-payout.component';
import { DirectPayApprovePayoutsComponent } from './direct-pay-approve-payouts/direct-pay-approve-payouts.component';
import { MatTableExporterModule } from 'mat-table-exporter';


const routes:Routes = [
  {path:'pending',component:PendingPayoutsComponent},
  {path:'detail/:mid/:pid',component:PayoutDetailsComponent},
  {path:'approve',component:ApprovePayoutsComponent},
  {path:'processed',component:ProcessedPayoutsComponent},
  {path:'direct/pay/processed',component:DirectPayProcessedPayoutComponent},
  {path:'p2p/approve',component:P2pApprovePayoutsComponent},
  {path:'p2p/status',component:PendingP2pPayoutComponent},
  {path:'direct/pay/approve',component:DirectPayApprovePayoutsComponent},
];


@NgModule({
  declarations: [PendingPayoutsComponent, PayoutDetailsComponent, ApprovePayoutsComponent, ProcessedPayoutsComponent, P2pApprovePayoutsComponent, PendingP2pPayoutComponent, DirectPayProcessedPayoutComponent, DirectPayApprovePayoutsComponent,],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HelpersModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableExporterModule
  ],
  providers: [
    HttpsService,
    // P2ppayouttimeslotsService
  ]
})
export class PayoutModule { }