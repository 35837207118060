import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { HttpsService } from 'src/app/services/https.service';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-payout-details',
  templateUrl: './payout-details.component.html',
  styleUrls: ['./payout-details.component.scss']
})
export class PayoutDetailsComponent implements OnInit {

  pid:any;
  mid:any;
  milestoneInfo:any;
  gigTimelineData:any
  constructor(private httpsService:HttpsService,private route:ActivatedRoute,private detectRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.pid=this.route.snapshot.params.pid
    this.mid=this.route.snapshot.params.mid
    
    this.httpsService.getGigTimer({paymentid:this.pid,milestoneid:this.mid}).subscribe((res:any)=>{
      this.milestoneInfo=res.data
      this.gigTimelineData=res.data.timerArray
      this.detectRef.detectChanges();
    },err=>{
      // console.log(err);
  })
  }
}
