import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';
import firebase from 'firebase';
import 'firebase/auth';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpsService } from './services/https.service';
import { HttpAuthInterceptor } from './interceptors/http.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { PreviewPopupComponent } from './preview-popup/preview-popup.component';
import { TimerlineComponent } from './timerline/timerline.component';
import { TimerdatePipe } from './helpers/pipes/timerdate.pipe';
import { NavComponent } from './nav/nav.component';
import { PayoutModule } from './payout/payout.module';
import { HelpersModule } from './helpers/helpers.module';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { TimeoutComponent } from './timeout/timeout.component';
import { UsersModule } from './users/users.module';
import { P2ppayouttimeslotsService } from './services/p2ppayouttimeslots.service';
import { ExcelServicesService } from './services/excel-services.service';

firebase.initializeApp(environment.firebase);
firebase.auth().settings.appVerificationDisabledForTesting = false;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PreviewPopupComponent,
    TimerlineComponent,
    NavComponent,
    AutoCompleteComponent,
    TimeoutComponent,
  ],
  entryComponents: [PreviewPopupComponent, TimerdatePipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    PayoutModule,
    UsersModule,
    HelpersModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    AuthService,
    P2ppayouttimeslotsService,
    HttpsService,
    ExcelServicesService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
