import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'slotDatePipe'
})
export class SlotDatePipePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return moment(value).format('DD, MMM hh:mm a');;
  }

}
