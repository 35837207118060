import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class P2ppayouttimeslotsService {
  private p2pslots = new BehaviorSubject({});
  sharedP2PSlotsData = this.p2pslots.asObservable()
  constructor() { }

  setP2PSlots(data:any){
    this.p2pslots.next(data);
    // console.log(this.p2pslots);
  }

}
