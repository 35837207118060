import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";
// import { AuthService } from '../services/auth.service';
import { mergeMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, take } from 'rxjs/operators';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  authService: any;
  constructor(private auth: AngularFireAuth) {
    // this.authService=this.injector.get(AuthService)
  }

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   this.authService.isLoggesIn().then((result:any) => {
  //     console.log(result);
  //     if(result){
  //       console.log('Inside Interceptor',result);
  //       request = request.clone({
  //         setHeaders:{
  //             'Authorization':`Bearer ${result}`,
  //             'Content-Type':"application/json"
  //         }
  //     })
  //     console.log(request);
  //   }
  //   }).catch((err:any) => {

  //   });
  //   return next.handle(request);
  // }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.auth.idToken.pipe(
      take(1),
      mergeMap((token: any) => {
        if (token) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          });
        }
        return next.handle(request);
      })
    );
  }
}
