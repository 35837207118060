
<div class="container">
<div class="row">
  <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
    <div *ngIf='milestoneInfo'>
    <h1 class="mt-5">Selected Milestone</h1>
    <h3>Milestone Details</h3>
    <p class="discription">{{milestoneInfo.milestoneDetails}}</p>
    <h3>Gig Name:</h3>
    <p class="discription">{{milestoneInfo.gigName}}</p>
    <h3>Seller Dettails:</h3>
    <p class="discription">{{milestoneInfo.sellerData.firstname}} <small>({{milestoneInfo.sellerData.mobile}})</small></p>
    <h3>Buyer Name:</h3>
    <p class="discription">{{milestoneInfo.buyerData.firstname}} <small>({{milestoneInfo.buyerData.mobile}})</small></p>
    <h3>Delivary Details:</h3>
    <p class="discription" *ngIf='milestoneInfo.delivaryDetalis'>{{milestoneInfo.delivaryDetalis}}</p>
    <p class="discription" *ngIf='!milestoneInfo.delivaryDetalis'>Not Provided</p>
  </div>
  </div>
  <div class="col-md-1 col-lg-1 col-xs-0 col-sm-0">
  </div>
  <div class="col-md-5 col-lg-5 col-xs-12 col-sm-12 align-items-center">
    <h1 class="mt-5">Gig Timeline</h1>
      <div *ngFor='let item of gigTimelineData' class="d-flex flex-row">
          <div class="d-flex flex-column align-items-center">
              <div [ngStyle]="{'background-color': item.color}" style="width: 20px;height: 20px;border-radius: 10px;"></div>
              <div *ngIf='!item.isDotted' [ngStyle]="{'background-color': item.strokeColor}" style="width: 2px;height: 40px;"></div>
              <div *ngIf='item.isDotted' style="width: 2px;height: 40px;border-left: dashed 2px #707070;"></div>
          </div>
          <div class="ml-2">
            <p class="mb-1">{{item.message}}</p>
            <small>{{item.date | timerdate}}</small>
          </div>
      </div>
  </div>
  </div>
</div>