<div *ngIf='isLoading' class="text-center" style="margin-top: 15%;">
  <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>
<section *ngIf='!isLoading' style='margin:20px 50px'>
    <div *ngIf="autoCompleteList.length!=0">
    <h1>Auto Complete gig</h1>
    <small>(3 days are completed from the date the seller delivered the following milestones)</small>
      <table class="table mt-4 table-striped">
          <thead style="background-color: #6d7ae0;">
            <tr>
              <th>Milestone Id<br><small>(Payment Id)</small></th>
              <th>Created Date</th>
              <th>Gig Name</th>
              <th>Milestone Details</th>
              <th>Buyer Name</th>
              <th>Seller Name</th>
              <th>Delivered Date</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of autoCompleteList">
              <td routerLink="/detail/{{item.milestone_id}}/{{item.milestoneData.paymentRef}}">{{ item.milestone_id }}<br><small>{{item.milestoneData.paymentRef}}</small></td>
              <td>{{ item.paymentDetails.createdAt | momentpipe }}</td>
              <td>{{ item.paymentDetails.gig_name }}</td>
              <td>{{ item.milestoneData.details }}</td>
              <td>{{ item.buyerName }}</td>
              <td>{{ item.sellerName }}</td>
              <td>{{ item.milestoneData.delivered_date | momentpipe}}</td>
              <td>Rs.{{ item.milestoneData.milestoneAmount }}</td>
              <td>
                <button
                [disabled]="clicked"
                (click)='clicked=true;markAsComplete(item.milestone_id)'
                class='btn btn-sm btn-success'>Complete</button>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    <div *ngIf="payoutWaitingList.length!=0">
        <h1>Undo Auto Complete gig</h1>
        <small>(these gigs will be payout ready 5 days after auto completed)</small>
        <table class="table mt-4 table-striped">
            <thead style="background-color: #6d7ae0;">
              <tr>
                <th>Milestone Id<br><small>(Payment Id)</small></th>
                <th>Created Date 
                <a>
                  <i class="fas fa-sort"></i>
                </a>
                </th>
                <th>Gig Name</th>
                <th>Milestone Details</th>
                <th>Buyer Name</th>
                <th>Seller Name</th>
                <th>Delivered Date
                  <a>
                    <i class="fas fa-sort"></i>
                  </a>
                </th>
                <th>Seller Beneficiary</th>
                <th>Amount</th>
                <th><small>No of days untill</small><br>Payout Ready</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of payoutWaitingList">
                <td routerLink="/detail/{{item.milestone_id}}/{{item.milestoneData.paymentRef}}">{{ item.milestone_id }}<br><small>{{item.milestoneData.paymentRef}}</small></td>
                <td>{{item.paymentDetails.createdAt | momentpipe}}</td>
                <td>{{item.paymentDetails.gig_name}}</td>
                <td>{{item.milestoneData.details}}</td>
                <td>{{ item.buyerName }}</td>
                <td>{{ item.sellerName }}</td>
                <td>{{ item.milestoneData.delivered_date | momentpipe}}</td>
                <td>{{ item.sellerAccount }}</td>
                <td>Rs.{{ item.milestoneData.milestoneAmount }}</td>
                <td>{{ item.milestoneData.accepted_date | daysdifference }}</td>
                <td>
                  <button
                  [disabled]="clicked"
                  (click)='clicked=true;markAsdelivered(item.milestone_id)'
                  class='btn btn-sm btn-danger m-1'>Undo</button>
                  <button 
                  *ngIf='item.sellerAccount=="Added" && item.milestoneData.order_settled==true'
                  [disabled]='clicked'
                  (click)='clicked=true;initiatePayout(item.milestone_id)'
                  class='btn btn-sm btn-success m-1'>Approve Now</button>
                </td>
              </tr>
            </tbody>
          </table>
    </div>
  </section>