import { Component, OnInit } from '@angular/core';
import { HttpsService } from 'src/app/services/https.service';

@Component({
  selector: 'app-approve-payouts',
  templateUrl: './approve-payouts.component.html',
  styleUrls: ['./approve-payouts.component.scss']
})
export class ApprovePayoutsComponent implements OnInit {

  isLoading:boolean = true;
  pendingPayouts:any[] = [];
  createdAtSort:boolean = true;
  deliveredAtSort:boolean = true;
  clicked:boolean = false;
  constructor(private httpsService:HttpsService) { }

  ngOnInit(): void {
    this.httpsService.getPayoutPreview().subscribe((res:any)=>{
      this.pendingPayouts=res.data
      
      this.pendingPayouts.sort((a,b)=>{return a.paymentDetails.createdAt._seconds - b.paymentDetails.createdAt._seconds})
      this.isLoading=false
    }, err=>{
      this.isLoading=false
    })
  }

  sortCreatedAt():void{
    if(this.createdAtSort){
    this.pendingPayouts.sort((a,b)=>{return b.paymentDetails.createdAt._seconds - a.paymentDetails.createdAt._seconds})
    this.createdAtSort=false
    }else{
      this.pendingPayouts.sort((a,b)=>{return a.paymentDetails.createdAt._seconds - b.paymentDetails.createdAt._seconds})
      this.createdAtSort=true
    }
  }

  sortDeliveredAt():void{
    if(this.deliveredAtSort){
    this.pendingPayouts.sort((a,b)=>{return b.milestoneData.delivered_date._seconds - a.milestoneData.delivered_date._seconds})
    this.deliveredAtSort=false
    }else{
      this.pendingPayouts.sort((a,b)=>{return a.milestoneData.delivered_date._seconds - b.milestoneData.delivered_date._seconds})
      this.deliveredAtSort=true
    }
  }

  initiatePayout(id:string){
    this.httpsService.initiateGigPayout({milestoneId:id}).subscribe((res:any)=>{
      console.log('************************************************************', res);
      var temp = this.pendingPayouts
      this.pendingPayouts = temp.filter(obj=>obj.milestone_id!=id)
      this.isLoading=false
      this.clicked=false
    }, err=>{
      console.log('#############################################################', err);
      let temp = this.pendingPayouts
      let index = temp.findIndex(obj=>obj.milestone_id==id)
      this.pendingPayouts[index].milestoneData.admin_attempted = true
      this.isLoading=false
      this.clicked=false
      if(err.error.data.hasOwnProperty('error')){
        window.alert(err.error.data.error.description)
      }else{
        window.alert(err.error.data.message)
      }
    })
  }

  reAttemptPayout(id:string){
    this.httpsService.reAttemptPayout({id:id}).subscribe((res:any)=>{
      let temp = this.pendingPayouts
      let index = temp.findIndex(obj=>obj.milestone_id==id)
      this.pendingPayouts[index].milestoneData.admin_attempted = false
      this.isLoading=false
      this.clicked=false
    }, err=>{
      this.isLoading=false
      this.clicked=false
      if(err.error.data.hasOwnProperty('error')){
        window.alert(err.error.data.error.description)
      }else{
        window.alert(err.error.data.message)
      }
    })
  }

}
