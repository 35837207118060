import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class HttpsService {

  constructor(private http:HttpClient) { }

  getProfile(){
    return this.http.get(environment.endpoint+'/profile')
  }

  getPayoutPreview(){
    return this.http.get(environment.endpoint+'/dashboard/payment/payouts/preview')
  }

  getPayoutStatus(){
    return this.http.get(environment.endpoint+'/dashboard/payment/payouts/status')
  }

  getProcessedPayouts(){
    return this.http.get(environment.endpoint+'/dashboard/payment/payouts/processed')
  }

  getGigTimer(params:any){
    return this.http.post(environment.endpoint+'/dashboard/payment/preview/gig',params)
  }

  updateVouchOrderSettled(params:any){
    return this.http.post(environment.endpoint+'/dashboard/payment/update/order/settled',params)
  }

  getAutoCompleteMilestones(){
    return this.http.get(environment.endpoint+'/dashboard/payment/preview/autocomplete');
  }

  autoCompleteGig(params:any){
    return this.http.post(environment.endpoint+'/dashboard/payment/update/gig/toggle',params);
  }

  initiateGigPayout(params:any){
    return this.http.post(environment.endpoint+'/dashboard/payment/payouts/settle',params);
  }

  reAttemptPayout(params:any){
    return this.http.post(environment.endpoint+'/dashboard/payment/payouts/settle/reattempt',params);
  }
  
  reinitiateGigPayout(params:any){
    return this.http.post(environment.endpoint+'/dashboard/payment/payouts/settle/reinitiate',params);
  }

  fetchAllUsersInfo(){
    return this.http.get(environment.endpoint+'/dashboard/users/fetch/all');
  }

  fetchSingleUserInfo(params:any){
    return this.http.post(environment.endpoint+'/dashboard/users/fetch',params);
  }

  makeBusinessUser(params:any){
    return this.http.post(environment.endpoint+'/dashboard/users/update/status',params);
  }

  fetchAllBusinessUsers(){
    return this.http.get(environment.endpoint+'/dashboard/users/business/fetch/all');
  }
  
  fetchSingleBusinnessUserInfo(params:any){
    return this.http.post(environment.endpoint+'/dashboard/users/business/fetch',params);
  }

  addBusinessUserAmount(params:any){
    return this.http.post(environment.endpoint+'/dashboard/users/business/amounnt/add',params);
  }

  addNewBrandNameForBusinessUser(params:any){
    return this.http.post(environment.endpoint+'/dashboard/users/business/brand/add',params);
  }

  getP2POrders(){
    return this.http.get(environment.endpoint+'/dashboard/p2p/orders/all');
  }

  getSingleOrders(params:any){
    return this.http.post(environment.endpoint+'/dashboard/p2p/orders/fetch',params);
  }
  
  getP2PPayoutPreview(){
    return this.http.get(environment.endpoint+'/dashboard/p2p/payouts/preview');
  }

  initiateP2PPayout(params:any){
    return this.http.post(environment.endpoint+'/dashboard/p2p/payouts/settle',params);
  }

  reinitiateP2PPayout(params:any){
    return this.http.post(environment.endpoint+'/dashboard/p2p/payouts/settle/reinitiate',params);
  }

  getP2PPayoutStatus(){
    return this.http.get(environment.endpoint+'/dashboard/p2p/payouts/status')
  }

  /**
   * This function is a https service for making the request to the required endpoint
   * @param params { id:<user_id>,panNumber:<entered_pan_number> }
   * @returns 
   */
  savePanDetials(params:any){
    return this.http.post(environment.endpoint+'/dashboard/users/update/pan',params);
  }

  getTdsData(){
    return this.http.get(environment.endpoint+'/dashboard/tds/pow/get');
  }

  getDirectPayTdsOrders(){
    return this.http.get(environment.endpoint+'/dashboard/tds/direct/pay/get');
  }



  getPANVerificationList() {
    return this.http.get(environment.endpoint+'/dashboard/pan/verification/get/all');
  }

  fetchSingleUserPanInfo(params:any) {
    return this.http.post(environment.endpoint+'/dashboard/pan/verification/get',params)
  }

  acceptPanVerificationRequest(params:any) {
    return this.http.post(environment.endpoint+'/dashboard/pan/verify/accept',params)
  }

  rejectPanVerificationRequest(params:any) {
    return this.http.post(environment.endpoint+'/dashboard/pan/verify/reject',params)
  }

  getDirectPayProcessedPayouts(){
    return this.http.get(environment.endpoint+'/dashboard/direct/pay/payouts/processed')
  }

  getAllDirectPayOrdersForPayouts(){
    return this.http.get(environment.endpoint+'/dashboard/direct/pay/orders/get')
  }

  initiateDirectPayOrderPayout(params:any){
    return this.http.post(environment.endpoint+'/dashboard/direct/pay/payouts/settle',params)
  }

  reAttemptDirectPayPayout(params:any){
    return this.http.post(environment.endpoint+'/dashboard/direct/pay/payouts/settle/reattempt',params)
  }

  editUserDetials(params:{
    uid:string,
    key:string,
    value:string
  }){
    return this.http.post(environment.endpoint+'/dashboard/users/update/details',params);
  }

  resetUserBeneficiaryDetails(params:{
    uid:string,
    bid:string
  }){
    return this.http.post(environment.endpoint + '/dashboard/users/reset/beneficiary',params);
  }

}
