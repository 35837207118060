<div *ngIf='isLoading' class="text-center" style="margin-top: 15%;">
  <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>
<section *ngIf='!isLoading' style='margin:20px 50px'>
    <h1>Approve Payouts</h1>
    <p *ngIf="pendingPayouts.length==0">No Pending Payouts</p>
      <table *ngIf="pendingPayouts.length>0" class="table mt-4 table-striped">
          <thead style="background-color: #6d7ae0;">
            <tr>
              <th>Milestone Id<br><small>(Payment Id)</small></th>
              <th>Created Date 
              <a (click)='sortCreatedAt()'>
                <i class="fas fa-sort"></i>
              </a>
              </th>
              <th>Gig Name</th>
              <th>Milestone Details</th>
              <th>Buyer Name</th>
              <th>Seller Name</th>
              <th>Delivered Date
                <a (click)='sortDeliveredAt()'>
                  <i class="fas fa-sort"></i>
                </a>
              </th>
              <th>Seller Beneficiary</th>
              <th>Amount</th>
              <th>tds<small>(tds percent)</small></th>
              <th>PAN Number</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of pendingPayouts">
              <td routerLink="/detail/{{item.milestone_id}}/{{item.milestoneData.paymentRef}}">{{ item.milestone_id }}<br><small>{{item.milestoneData.paymentRef}}</small></td>
              <td>{{item.paymentDetails.createdAt | momentpipe}}</td>
              <td>{{item.paymentDetails.gig_name}}</td>
              <td>{{item.milestoneData.details}}</td>
              <td>{{ item.buyerName }}</td>
              <td>{{ item.sellerName }}</td>
              <td>{{ item.milestoneData.delivered_date | momentpipe}}</td>
              <td>{{ item.sellerAccount }}</td>
              <td>Rs.{{ item.milestoneData.milestoneAmount }}</td>
              <td *ngIf = "item.paymentDetails.hasOwnProperty('tds') && item.paymentDetails.tds>0">Rs. {{item.paymentDetails.tds}} <small>( {{item.paymentDetails.tds_percent * 100}}% )</small></td>
              <td *ngIf = "!item.paymentDetails.hasOwnProperty('tds') || (item.paymentDetails.tds == 0)">Not Applicable</td>
              <td>
                <p *ngIf="item.pan_number" class="text-success">
                  Added<br>
              <small>{{ item.pan_number }}</small>
              </p>
              <p *ngIf="!item.pan_number" class="text-danger">
                  Not Added
              </p>
              </td>
              <td>
                <button 
                *ngIf='item.sellerAccount=="Added" 
                && item.milestoneData.paymentRef!=="H8mVJm9GsCjYpUQ5cXBT" 
                && item.milestoneData.admin_attempted
                && item.milestoneData.paymentRef!=="E8jzIaIPJqqHZNrLOJKR" && (!item.paymentDetails.tds || item.paymentDetails.tds==0 || (item.paymentDetails.tds>0 && item.pan_number))'
                [disabled]='clicked'
                (click)='clicked=true;reAttemptPayout(item.milestone_id)'
                class='btn btn-sm btn-warning fs-8'>Re-Attempt</button>
                <button 
                *ngIf='item.sellerAccount=="Added" 
                && item.milestoneData.paymentRef!=="H8mVJm9GsCjYpUQ5cXBT" 
                && !item.milestoneData.admin_attempted
                && item.milestoneData.paymentRef!=="E8jzIaIPJqqHZNrLOJKR" && (!item.paymentDetails.tds || item.paymentDetails.tds==0 || (item.paymentDetails.tds>0 && item.pan_number))'
                [disabled]='clicked'
                (click)='clicked=true;initiatePayout(item.milestone_id)'
                class='btn btn-sm btn-success'>Approve</button>
              </td>
            </tr>
          </tbody>
        </table>
  </section>