import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpsService } from 'src/app/services/https.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-direct-pay-processed-payout',
  templateUrl: './direct-pay-processed-payout.component.html',
  styleUrls: ['./direct-pay-processed-payout.component.scss']
})
export class DirectPayProcessedPayoutComponent implements OnInit {
  filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    buyerMobile: new FormControl(),
    sellerMobile: new FormControl(),
    buyerName: new FormControl(),
    sellerName: new FormControl(),
  });
  isLoading: boolean = true;
  processedPayouts: any[] = [];
  clicked: boolean = false;
  displayedColumns: string[] = [
    'order_id',
    'buyerName',
    'sellerName',
    'gig_name',
    'total_amount',
    'sellerMobile',
    'buyerMobile',
    'creationSeconds',
    'payoutSeconds',
  ];
  dataSource: any;
  usersData: any;
  @ViewChild(MatSort) sort: any;
  @ViewChild(MatPaginator) paginator: any;
  constructor(private httpsService: HttpsService) {}

  ngOnInit(): void {
    this.httpsService.getDirectPayProcessedPayouts().subscribe(
      (res: any) => {        
        this.processedPayouts = res.data;
        this.processedPayouts.sort((a, b) => {
          return b.payoutSeconds - a.payoutSeconds;
        });
        this.dataSource = new MatTableDataSource(this.processedPayouts);
        this.isLoading = false;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data: any, filter: any) => {
          return (this.buyerMobile?data.buyerMobile.trim().toLowerCase().match(this.buyerMobile):true) &&
          (this.sellerMobile?data.sellerMobile.trim().toLowerCase().match(this.sellerMobile):true) &&
          (this.sellerName?data.sellerName.trim().toLowerCase().match(this.sellerName):true) &&
          (this.buyerName?data.buyerName.trim().toLowerCase().match(this.buyerName):true) &&
          (this.fromDate && this.toDate?data.payoutSeconds >= this.fromDate && data.payoutSeconds <= this.toDate:true)
          // if(filter == 'reset'){
          //   return true
          // }
          // else{
          //   let res = false;
          //   if (
          //     data.payoutSeconds >= this.fromDate &&
          //     data.payoutSeconds <= this.toDate
          //   ) {
          //     res = true;
          //   } else if (
          //     this.buyerMobile &&
          //     data.buyerMobile.trim().toLowerCase().match(this.buyerMobile)
          //   ) {
          //     res = true;
          //   } else if (
          //     this.sellerMobile &&
          //     data.sellerMobile.trim().toLowerCase().match(this.sellerMobile)
          //   ) {
          //     res = true;
          //   } else if (
          //     this.buyerName &&
          //     data.buyerName.trim().toLowerCase().match(this.buyerName)
          //   ) {
          //     res = true;
          //   } else if (
          //     this.sellerName &&
          //     data.sellerName.trim().toLowerCase().match(this.sellerName)
          //   ) {
          //     res = true;
          //   } else if(!this.buyerMobile && !this.sellerMobile && !this.buyerName && !this.sellerName){
          //     return true
          //   }
          //   return res;
          // }
        };
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  get fromDate() {
    return this.filterForm?.get('fromDate')?.value?.getTime() / 1000;
  }
  get toDate() {
    let result = new Date(this.filterForm?.get('toDate')?.value);
    result.setDate(result.getDate() + 1);
    return result.getTime() / 1000;
  }

  get buyerMobile() {
    return this.filterForm?.get('buyerMobile')?.value?.trim()?.toLowerCase();
  }
  get sellerMobile() {
    return this.filterForm?.get('sellerMobile')?.value?.trim()?.toLowerCase();
  }
  get buyerName() {
    return this.filterForm?.get('buyerName')?.value?.trim()?.toLowerCase();
  }
  get sellerName() {
    return this.filterForm?.get('sellerName')?.value?.trim()?.toLowerCase();
  }

  applyFilter() {
    this.dataSource.filter = '' + Math.random();
  }

  resetForm() {
    this.filterForm.reset();
    this.dataSource.filter = 'reset';
  }
}
