import { Component, OnInit } from '@angular/core';
import { HttpsService } from 'src/app/services/https.service';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit {

  isLoading:boolean = true;
  autoCompleteList:any[] = [];
  payoutWaitingList:any[] = [];
  createdAtSort:boolean = true;
  deliveredAtSort:boolean = true;
  clicked:boolean=false
  constructor(private httpsService:HttpsService) { }

  ngOnInit(): void {
    this.httpsService.getAutoCompleteMilestones().subscribe((res:any)=>{
      this.autoCompleteList=res.data.autocompleteReadyMilestones
      this.payoutWaitingList=res.data.autocompleteWaitingMilestones
      // this.milestonesData.sort((a,b)=>{return a.paymentDetails.createdAt._seconds - b.paymentDetails.createdAt._seconds})
      this.isLoading=false
    }, err=>{
      this.isLoading=false
    })  
  }

  markAsComplete(id:string){
    this.httpsService.autoCompleteGig({milestone_id:id,mode:'complete'}).subscribe((res:any)=>{
      this.clicked=false,
      this.autoCompleteList=res.data.autocompleteReadyMilestones
      this.payoutWaitingList=res.data.autocompleteWaitingMilestones
    }, err=>{
      this.isLoading=false
    })
  }

  markAsdelivered(id:string){
    this.httpsService.autoCompleteGig({milestone_id:id,mode:'redo'}).subscribe((res:any)=>{
      this.clicked=false,
      this.autoCompleteList=res.data.autocompleteReadyMilestones
      this.payoutWaitingList=res.data.autocompleteWaitingMilestones
    }, err=>{
      this.isLoading=false
    })
  }

  initiatePayout(id:string){
    this.httpsService.initiateGigPayout({milestoneId:id}).subscribe((res:any)=>{
      var temp = this.payoutWaitingList
      this.payoutWaitingList = temp.filter(obj=>obj.milestone_id!=id)
      this.isLoading=false
      this.clicked=false
    }, err=>{
      this.isLoading=false
      this.clicked=false
      if(err.error.data.error.description){
        window.alert(err.error.data.error.description)
      }else{
        window.alert(err.error.data.message)
      }
    })
  }  

}
