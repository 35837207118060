import { Injectable } from '@angular/core';
// import {hmacSHA512} from 'crypto-js';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isLoggedIn = new BehaviorSubject(false);
  sharedisLoggedIn = this.isLoggedIn.asObservable();


  constructor(public afAuth:AngularFireAuth) { }


  isLoggesIn(){

    var promise = new Promise((resolve, reject) => {
      this.afAuth.idToken.subscribe(
        res=>{
          if (res) {
                // console.log('user is logged in');
                this.isLoggedIn.next(true)
                resolve(res);
              } else {
                // console.log('user not logged in');
                resolve(false);
              }
          })
    });

    return promise;
    
    }

    logout(){
      return this.afAuth.signOut().then(() => {
        this.isLoggedIn.next(false)
        location.reload();
      })
      }
    
      markloggedIn() {
        this.isLoggedIn.next(true)
        this.afAuth.idToken.subscribe(res=>{
          if(res){
            sessionStorage.setItem('loggedIn',CryptoJS.AES.encrypt(res,'dash@secret#1@2$3').toString())
          }
        })
      }
}
