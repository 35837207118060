<div class="container-fluid pt-5">
  <div class="container">
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f0f0f0;
        position: absolute;
        width: 80%;
        margin: -10px 0 0 -10px;
        height: 100%;
        z-index: 1;
      "
      *ngIf="isLoading"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <section>
      <div class="row">
        <div class="col-xl-4 col-sm-6 col-12">
          <div
            class="bg-white shadow-sm"
            style="border-radius: 20px; padding: 40px 30px"
          >
            <div class="usersGeneralInfo">
              <p class="font-weight-bold" >first name</p>
              <p *ngIf='!editFirstName' (click)='editFirstName=true'>{{ user_info.firstname }}</p>
              <input *ngIf="editFirstName" autofocus [(ngModel)]="newfirstName" type="string" (focusout)="editFirstName=false;onFirstNameInputFocusOut()"/>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">last name</p>
              <p *ngIf='!editLastName' (click)='editLastName=true'>{{ user_info.lastname }}</p>
              <input *ngIf="editLastName" [(ngModel)]="newLastName" type="string" (focusout)="editLastName=false;onLastNameInputFocusOut()"/>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Mobile</p>
              <p>{{ user_info.mobile }}</p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Username</p>
              <p>{{ user_info.username }}</p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Email</p>
              <p style="font-size: 12px">{{ user_info.email }}</p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Account Type</p>
              <p>{{ user_info.account_type }}</p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Pan Number</p>
              <p>
                {{ user_info.pan_number ? user_info.pan_number : "--" }}
              </p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Referral code</p>
              <p>
                {{ user_info.referral_code ? user_info.referral_code : "--" }}
              </p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Signed up date</p>
              <p>{{ user_info.createdAt | momentpipe }}</p>
            </div>
            
            
            <div *ngIf="pow_data" class="mt-4">
              <div class="d-flex justify-content-between mb-2">
                <h2 class="mb-0 font-weight-bold">POW Beneficiary Details</h2>
                <button class="btn btn-danger px-1 py-0 m-0 rounded"
                (click)="isResettingPOWAccount=true;resetAccountDetails()"
                [disabled] = 'isResettingPOWAccount'
                > Reset</button>
              </div>
              <div *ngIf="pow_data.account_details.type == 'bank_account'">
                <div class="usersGeneralInfo">
                  <p class="font-weight-bold">Account Number</p>
                  <p>{{ pow_data.account_details.bankAccount }}</p>
                </div>
                <div class="usersGeneralInfo">
                  <p class="font-weight-bold">IFSC</p>
                  <p>{{ pow_data.account_details.ifsc }}</p>
                </div>
              </div>
              <div *ngIf="pow_data.account_details.type == 'vpa'">
                <div class="usersGeneralInfo">
                  <p class="font-weight-bold">UPI Id</p>
                  <p>{{ pow_data.account_details.vpa }}</p>
                </div>
              </div>
            </div>


            <div *ngIf="direct_pay_data" class="mt-4">
              <div class="d-flex justify-content-between mb-2">
                <h2 class="mb-0 font-weight-bold">Direct Pay Beneficiary Details</h2>
              </div>
              <div *ngIf="direct_pay_data.account_details.type == 'bank_account'">
                <div class="usersGeneralInfo">
                  <p class="font-weight-bold">Account Number</p>
                  <p>{{ direct_pay_data.account_details.bankAccount }}</p>
                </div>
                <div class="usersGeneralInfo">
                  <p class="font-weight-bold">IFSC</p>
                  <p>{{ direct_pay_data.account_details.ifsc }}</p>
                </div>
              </div>
              <div *ngIf="direct_pay_data.account_details.type == 'vpa'">
                <div class="usersGeneralInfo">
                  <p class="font-weight-bold">UPI Id</p>
                  <p>{{ direct_pay_data.account_details.vpa }}</p>
                </div>
              </div>
            </div>

          
            <button
              *ngIf="user_info.user_type != 'BUSINESS'"
              (click)="convertToBusinessUser()"
              [disabled]="isMakingBusinessUserLoading"
              type="button"
              class="btn btn-success mt-2"
            >
              Make a Business user
            </button>
            <button
            type="button"
            class="btn btn-info mt-2"
            data-toggle="collapse"
            data-target="#demo"
          >
            Update Pan Details
          </button>
          <div id="demo" class="collapse">
            <div class="input-group mt-4 mb-3">
              <input
                name="enteredPanNumber"
                [(ngModel)]="enteredPanNumber"
                type="text"
                class="form-control"
                placeholder="Please Enter Pan Number"
                value="enteredPanNumber"
              />
              <button 
              class="btn btn-success ml-2" 
              data-toggle="collapse" 
              data-target="#demo"
              [disabled]="isSavingPanNumber"
              (click)="updatePanDetails()"
              >Save</button>
            </div>
          </div>
          </div>
        </div>
        <div class="col-xl-8 col-sm-6 col-12">
          <div class="row">
            <div class="col-xl-3 col-sm-6 col-12">
              <div
                (click)="test(0)"
                class="card shadow-sm mt-3"
                [ngStyle]="{
                  'background-color': index == 0 ? '#0e1732' : '#FFF'
                }"
              >
                <div class="card-content">
                  <div class="card-body">
                    <span
                      class="cardTitle"
                      [ngStyle]="{ color: index == 0 ? '#FFF' : '#000' }"
                      >Total Gigs</span
                    >
                    <h3
                      class="cardValue"
                      [ngStyle]="{ color: index == 0 ? '#FFF' : '#000' }"
                    >
                      {{ user_info.total_gigs }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
              <div
                (click)="test(1)"
                class="card mt-3"
                [ngStyle]="{
                  'background-color': index == 1 ? '#0e1732' : '#FFF'
                }"
              >
                <div class="card-content">
                  <div class="card-body">
                    <span
                      class="cardTitle"
                      [ngStyle]="{ color: index == 1 ? '#FFF' : '#000' }"
                      >Total Gigs Completed</span
                    >
                    <h3
                      class="cardValue"
                      [ngStyle]="{ color: index == 1 ? '#FFF' : '#000' }"
                    >
                      {{ user_info.total_gigs_completed }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
              <div
                (click)="test(2)"
                class="card mt-3"
                [ngStyle]="{
                  'background-color': index == 2 ? '#0e1732' : '#FFF'
                }"
              >
                <div class="card-content">
                  <div class="card-body">
                    <span
                      class="cardTitle"
                      [ngStyle]="{ color: index == 2 ? '#FFF' : '#000' }"
                      >Total Gigs as seller</span
                    >
                    <h3
                      class="cardValue"
                      [ngStyle]="{ color: index == 2 ? '#FFF' : '#000' }"
                    >
                      {{ user_info.gigs_as_seller }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
              <div
                (click)="test(3)"
                class="card mt-3"
                [ngStyle]="{
                  'background-color': index == 3 ? '#0e1732' : '#FFF'
                }"
              >
                <div class="card-content">
                  <div class="card-body">
                    <span
                      class="cardTitle"
                      [ngStyle]="{ color: index == 3 ? '#FFF' : '#000' }"
                      >Gigs completed as seller</span
                    >
                    <h3
                      class="cardValue"
                      [ngStyle]="{ color: index == 3 ? '#FFF' : '#000' }"
                    >
                      {{ user_info.gigs_completed_as_seller }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 col-sm-6 col-12">
              <div
                (click)="test(4)"
                class="card mt-3"
                [ngStyle]="{
                  'background-color': index == 4 ? '#0e1732' : '#FFF'
                }"
              >
                <div class="card-content">
                  <div class="card-body">
                    <span
                      class="cardTitle"
                      [ngStyle]="{ color: index == 4 ? '#FFF' : '#000' }"
                      >Active Gigs as seller</span
                    >
                    <h3
                      class="cardValue"
                      [ngStyle]="{ color: index == 4 ? '#FFF' : '#000' }"
                    >
                      {{ user_info.gigs_active_as_seller }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
              <div
                (click)="test(5)"
                class="card mt-3"
                [ngStyle]="{
                  'background-color': index == 5 ? '#0e1732' : '#FFF'
                }"
              >
                <div class="card-content">
                  <div class="card-body">
                    <span
                      class="cardTitle"
                      [ngStyle]="{ color: index == 5 ? '#FFF' : '#000' }"
                      >Total Gigs as buyer</span
                    >
                    <h3
                      class="cardValue"
                      [ngStyle]="{ color: index == 5 ? '#FFF' : '#000' }"
                    >
                      {{ user_info.gigs_as_buyer }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
              <div
                (click)="test(6)"
                class="card mt-3"
                [ngStyle]="{
                  'background-color': index == 6 ? '#0e1732' : '#FFF'
                }"
              >
                <div class="card-content">
                  <div class="card-body">
                    <span
                      class="cardTitle"
                      [ngStyle]="{ color: index == 6 ? '#FFF' : '#000' }"
                      >Gigs completed as buyer</span
                    >
                    <h3
                      class="cardValue"
                      [ngStyle]="{ color: index == 6 ? '#FFF' : '#000' }"
                    >
                      {{ user_info.gigs_completed_as_buyer }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
              <div
                (click)="test(7)"
                class="card mt-3"
                [ngStyle]="{
                  'background-color': index == 7 ? '#0e1732' : '#FFF'
                }"
              >
                <div class="card-content">
                  <div class="card-body">
                    <span
                      class="cardTitle"
                      [ngStyle]="{ color: index == 7 ? '#FFF' : '#000' }"
                      >Active Gigs as buyer</span
                    >
                    <h3
                      class="cardValue"
                      [ngStyle]="{ color: index == 7 ? '#FFF' : '#000' }"
                    >
                      {{ user_info.gigs_active_as_buyer }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section style="margin: 20px 50px">
      <h1>{{ tableTitle }}</h1>
      <mat-form-field>
        <input
          (keyup)="applyFilter($any($event.target).value)"
          matInput
          placeholder="Search"
        />
      </mat-form-field>
      <div class="mat-elevation-z8">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="table mt-4 table-striped"
        >
          <ng-container matColumnDef="doc_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
            <td mat-cell *matCellDef="let element">{{ element.doc_id }}</td>
          </ng-container>
          <ng-container matColumnDef="gig_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Name</th>
            <td mat-cell *matCellDef="let element">{{ element.gig_name }}</td>
          </ng-container>
          <ng-container matColumnDef="mode_processed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mode</th>
            <td mat-cell *matCellDef="let element">
              {{ element.mode_processed }}
            </td>
          </ng-container>
          <ng-container matColumnDef="to_user_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Gig partner
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.to_user_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_amount }}
            </td>
          </ng-container>
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let element">{{ element.category }}</td>
          </ng-container>
          <ng-container matColumnDef="seconds">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              creation date
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | momentpipe }}
            </td>
          </ng-container>
          <ng-container matColumnDef="completed_seconds">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-6">
              Buyer Accepting date
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.completion_date | momentpipe }}
            </td>
          </ng-container>
          <ng-container matColumnDef="order_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              order_status
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.order_status }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            routerLink="/users/detail/gig/{{ row.doc_id }}"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
        <mat-paginator
          [pageSizeOptions]="[5, 15, 25]"
          showFirstLastButtons
          aria-label="Select page of periodic elements"
        >
        </mat-paginator>
      </div>
    </section>
  </div>
</div>
