<div>
<div class="row flex-row justify-content-between mr-1 ml-1">
<h2>Settlement Preview</h2>
<a href="javascript:void(0)" (click)="close()"><i class="fas fa-times"></i></a>
</div>
<div mat-dialog-content>
    <h4>Settlemt Id:<span>{{settlemtId}}</span></h4>
    <h4>Settlemt Id:<span>{{createdAt}}</span></h4>
    <h2>Payments of this settlement:</h2>
    <table *ngIf='paymentData.length!=0' class="table mt-4 table-striped">
        <thead class="thead-dark">
          <tr>
            <th>Razorpay Payment ID</th>
            <th>createdAt</th>
            <th>method</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paymentData">
            <td>{{item.id}}</td>
            <td>{{item.created_at | momentpipe}}</td>
            <td>{{item.method}}</td>
            <td>{{item.amount}}</td>
          </tr>
        </tbody>
      </table>
      <h2>Milestones Info:</h2>
      <p *ngIf='previewData.length==0'>No Milestones to update</p>
      <table *ngIf='previewData.length!=0' class="table mt-4 table-striped">
        <thead class="thead-dark">
          <tr>
            <th>Razorpay Payment ID</th>
            <th>Gig Name</th>
            <th>Milestone Details</th>
            <th>Buyer Name</th>
            <th>Seller Name</th>
            <th>Seller Beneficiary</th>
            <th>Milestone Status</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of previewData">
            
            <td><a href="javascript:void(0)"  (click)="preview(item)">{{item.paymentId}}</a></td>
            <td><a href="javascript:void(0)"  (click)="preview(item)">{{item.gigName}}</a></td>
            <td><a href="javascript:void(0)"  (click)="preview(item)">{{item.milestoneDetails}}</a></td>
            <td><a href="javascript:void(0)"  (click)="preview(item)">{{item.buyerName}}</a></td>
            <td><a href="javascript:void(0)"  (click)="preview(item)">{{item.sellerName}}</a></td>
            <td><a href="javascript:void(0)"  (click)="preview(item)">{{item.sellerAccount}}</a></td>
            <td><a href="javascript:void(0)"  (click)="preview(item)"><a href="javascript:void(0)" (click)="preview(item)" >{{item.milestoneStatus}}</a></a></td>
            <td><a href="javascript:void(0)"  (click)="preview(item)">{{item.milestoneAmount}}</a></td>
          </tr>
        </tbody>
      </table>
      <h1 *ngIf='selectedFlag' class="mt-5">Selected Milestone</h1>
      <div class="row pl-3 pr-3 mt-5">
        <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
          <div *ngIf='selectedFlag'>
          <h3>Milestone Details</h3>
          <p>{{selectedMilestoneData.milestoneDetails}}</p>
          <h3>Gig Name:</h3>
          <p>{{selectedMilestoneData.gigName}}</p>
          <h3>Seller Name:</h3>
          <p>{{selectedMilestoneData.sellerName}}</p>
          <h3>Buyer Name:</h3>
          <p>{{selectedMilestoneData.buyerName}}</p>
          <h3>Seller Mobile:</h3>
          <p>{{selectedMilestoneData.sellerMobile}}</p>
          <h3>Buyer Mobile:</h3>
          <p>{{selectedMilestoneData.buyerMobile}}</p>
          <h3>Delivary Details:</h3>
          <p>{{selectedMilestoneData.delivaryDetalis}}</p>
        </div>
        </div>
        <div class="col-md-1 col-lg-1 col-xs-0 col-sm-0">
        </div>
        <div class="col-md-5 col-lg-5 col-xs-12 col-sm-12 align-items-center">
            <div *ngFor='let item of gigTimelineData' class="d-flex flex-row">
                <div class="d-flex flex-column align-items-center">
                    <div [ngStyle]="{'background-color': item.color}" style="width: 20px;height: 20px;border-radius: 10px;"></div>
                    <div *ngIf='!item.isDotted' [ngStyle]="{'background-color': item.strokeColor}" style="width: 2px;height: 40px;"></div>
                    <div *ngIf='item.isDotted' style="width: 2px;height: 40px;border-left: dashed 2px #707070;"></div>
                </div>
                <div class="ml-2">
                  <p class="mb-1">{{item.message}}</p>
                  <small>{{item.date | timerdate}}</small>
                </div>
            </div>
        </div>
        </div>
</div>
<button *ngIf='previewData.length!=0' class="btn btn-primary text-light" [mat-dialog-close]="true" cdkFocusInitial>Process</button>
</div>