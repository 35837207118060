import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllUsersComponent } from './all-users/all-users.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { Routes, RouterModule } from '@angular/router';
import { HelpersModule } from '../helpers/helpers.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { HttpsService } from '../services/https.service';
import { GigDetailsComponent } from './gig-details/gig-details.component';
import { AllBusinessUsersComponent } from './all-business-users/all-business-users.component';
import { AddAmountComponent } from './add-amount/add-amount.component';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  { path: '', component: AllUsersComponent },
  { path: 'detail/:id', component: UserDetailsComponent },
  { path: 'detail/gig/:id', component: GigDetailsComponent },
  { path: 'business', component: AllBusinessUsersComponent },
  { path: 'amount/add/:id', component: AddAmountComponent },
];

@NgModule({
  declarations: [
    AllUsersComponent,
    UserDetailsComponent,
    GigDetailsComponent,
    AllBusinessUsersComponent,
    AddAmountComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HelpersModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    FormsModule
  ],
  providers: [HttpsService],
})
export class UsersModule {}
