import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentpipePipe } from './pipes/momentpipe.pipe';
import { TimerdatePipe } from './pipes/timerdate.pipe';
import { DaysdifferencePipe } from './pipes/daysdifference.pipe';
import { SlotPipePipe } from './pipes/slot-pipe.pipe';
import { SlotDatePipePipe } from './pipes/slot-date-pipe.pipe';

@NgModule({
  declarations: [
    MomentpipePipe,
    TimerdatePipe,
    DaysdifferencePipe,
    SlotPipePipe,
    SlotDatePipePipe,
  ],
  imports: [CommonModule],
  exports: [
    MomentpipePipe,
    TimerdatePipe,
    DaysdifferencePipe,
    SlotPipePipe,
    SlotDatePipePipe,
  ],
})
export class HelpersModule {}
