import { Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { P2ppayouttimeslotsService } from 'src/app/services/p2ppayouttimeslots.service';
import { HttpsService } from '../../services/https.service';

@Component({
  selector: 'app-p2p-approve-payouts',
  templateUrl: './p2p-approve-payouts.component.html',
  styleUrls: ['./p2p-approve-payouts.component.scss']
})
export class P2pApprovePayoutsComponent implements OnInit {

  displayedColumns: string[] = ['index','seller_mobile','amount','orders','slotStartTime']
  dataSource:any
  payoutsData:any;
  isLoading:boolean=true;
  clicked:boolean=false;
  @ViewChild(MatSort) sort: any;
  @ViewChild(MatPaginator) paginator: any;
  constructor(private httpsService:HttpsService,private p2pDataService:P2ppayouttimeslotsService) { }

  ngOnInit(): void {
    this.httpsService.getP2PPayoutPreview().subscribe((res:any)=>{      
      this.payoutsData=res.data;
      this.payoutsData = this.payoutsData.map((item:any,index:any)=>({...item,id:index}))
      this.dataSource = new MatTableDataSource(this.payoutsData);
      this.isLoading=false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.p2pDataService.setP2PSlots(this.payoutsData)
    }, err=>{
      this.isLoading=false;
    })
  }

  
  applyFilter(text:string){
    this.dataSource.filter = text.trim().toLowerCase()
  }

  test(params:any){
  }


  initiatePayout(id:any){
    const payoutInfo = this.payoutsData.find((item:any)=>(item.id == id));
    let params = {
      slotStartTime:payoutInfo.slotStartTime,
      slotEndTime:payoutInfo.slotEndTime,
      amount:payoutInfo.amount,
      seller_mobile:payoutInfo.seller_mobile,
      orders:payoutInfo.orders
    }
    this.httpsService.initiateP2PPayout(params).subscribe((res:any)=>{
      var temp = this.payoutsData
      this.payoutsData = temp.filter((obj:any)=>obj.id!=id);
      this.p2pDataService.setP2PSlots(this.payoutsData)
      this.dataSource.data = this.payoutsData;
      this.isLoading=false
      this.clicked=false
    }, err=>{
      this.isLoading=false
      this.clicked=false
      if(err.error.data.message){
        window.alert(err.error.data.message)
      }
      if(err.error.data.error.description){
        window.alert(err.error.data.error.description)
      }else{
        window.alert(err.error.data.message)
      }
    })
  }

}
