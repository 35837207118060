<div
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: 1;
  "
  *ngIf="isLoading"
>
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<section style="margin: 20px 50px">
  <div class="row">
    <div class="col-12 col-md-4">
      <h1>Processed Payouts</h1>
    </div>
    <div class="col-12 col-md-8">
      <form class="date-block" [formGroup]="filterForm">
        <div class="d-flex justify-content-end">
          <div class="date-block__list mr-2 fs-7">
            <mat-form-field>
              <input
                matInput
                placeholder="Buyer mobile"
                formControlName="buyerMobile"
              />
            </mat-form-field>
          </div>
          <div class="date-block__list mr-2 fs-7">
            <mat-form-field>
              <input
                matInput
                placeholder="Seller mobile"
                formControlName="sellerMobile"
              />
            </mat-form-field>
          </div>
          <div class="date-block__list mr-2 fs-7">
            <mat-form-field>
              <input
                matInput
                placeholder="Buyer Name"
                formControlName="buyerName"
              />
            </mat-form-field>
          </div>
          <div class="date-block__list mr-2 fs-7">
            <mat-form-field>
              <input
                matInput
                placeholder="Seller Name"
                formControlName="sellerName"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div class="date-block__list mr-2 fs-7">
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="fromDate"
                placeholder="From Date"
                formControlName="fromDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fromDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="date-block__list mr-2 fs-7">
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="toDate"
                placeholder="To Date"
                formControlName="toDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="toDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            color="primary"
            class="btn btn-success mr-2 filter-button"
            (click)="applyFilter()"
          >
            Filter
          </button>
          <button
            color="primary"
            class="btn btn-danger mr-2 filter-button"
            (click)="resetForm()"
          >
            Clear
          </button>
          <!-- <button 
          color="primary"
          class="btn btn-primary filter-button"
          mat-raised-button 
          (click) = "excel_export()"
          >
          Excel Export
        </button> -->
        </div>
      </form>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table
      mat-table
      matTableExporter
      #exporter="matTableExporter" 
      [dataSource]="dataSource"
      matSort
      class="table mt-4 table-striped"
    >
      <ng-container matColumnDef="milestone_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Milestone Id</th>
        <td mat-cell *matCellDef="let element">
          {{ element.milestone_id }}<br /><small>{{
            element.milestoneData.paymentRef
          }}</small
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="gig_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gig Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.paymentDetails.gig_name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="buyerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Buyer Name</th>
        <td mat-cell *matCellDef="let element">{{ element.buyerName }}</td>
      </ng-container>
      <ng-container matColumnDef="sellerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Seller Name</th>
        <td mat-cell *matCellDef="let element">{{ element.sellerName }}</td>
      </ng-container>
      <ng-container matColumnDef="buyerMobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Buyer Mobile</th>
        <td mat-cell *matCellDef="let element">{{ element.buyerMobile }}</td>
      </ng-container>
      <ng-container matColumnDef="sellerMobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Seller Mobile</th>
        <td mat-cell *matCellDef="let element">{{ element.sellerMobile }}</td>
      </ng-container>
      <ng-container matColumnDef="creationSeconds">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.paymentDetails.createdAt | momentpipe }}
        </td>
      </ng-container>
      <ng-container matColumnDef="completionSeconds">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Buyer Accepted date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.milestoneData.accepted_date | momentpipe }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payoutSeconds">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Payout creation date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.milestoneData.transferDetails.createdAt | momentpipe }}
        </td>
      </ng-container>
      <ng-container matColumnDef="milestoneAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let element">
          {{ element.milestoneData.milestoneAmount }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        routerLink="/users/detail/{{ row.id }}"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[25, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</section>
