<header class="site-header shadow-sm bg-white">
    <div id="header-wrap">
      <div class="container">
        <div class="row">
          <div class="col d-flex align-items-center justify-content-between">
            <a class="navbar-brand" href="#">
              <img src="assets/logo.svg" width="100" height="60" alt="">
            </a>
            <nav *ngIf='isLoggedIn' class="navbar navbar-expand-lg navbar-light ml-auto">            
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Settle Gigs</a>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Payouts
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">Approve Payouts</a>
                        <ul class="dropdown-menu">
                          <a class="dropdown-item" routerLink="/payout/approve">Vouch</a>
                          <a class="dropdown-item" routerLink="/payout/direct/pay/approve">Direct Pay</a>
                        </ul>
                      </li>
                      <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">Pending Payouts</a>
                        <ul class="dropdown-menu">
                          <a class="dropdown-item" routerLink="/payout/pending">Vouch</a>
                          <a class="dropdown-item" routerLink="/payout/p2p/status">P2P</a>
                        </ul>
                      </li>
                    </ul>

                  </li>
                  <!-- <li class="nav-item dropdown">
                    <a class="nav-link" routerLink="/tds" routerLinkActive="active">TDS</a>
                  </li> -->
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      TDS
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">TDS Orders</a>
                        <ul class="dropdown-menu">
                          <a class="dropdown-item" routerLink="/tds/pow">POW Orders</a>
                          <a class="dropdown-item" routerLink="/tds/direct-pay">Direct Pay Orders</a>
                        </ul>
                      </li>
                      <a class="dropdown-item" routerLink="/tds/pan/approve">Approve Tax verification</a>
                    </div>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Processed Payouts
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <a class="dropdown-item" routerLink="/payout/processed">POW payouts</a>
                      <a class="dropdown-item" routerLink="/direct/pay/processed">Direct Pay Payouts</a>
                    </div>
                  </li>
                  <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/payout/processed" routerLinkActive="active">Processed Payouts</a>
                  </li> -->
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/auto-complete" routerLinkActive="active">Autocomplete</a>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Users
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <a class="dropdown-item" routerLink="/users">All Users</a>
                      <a class="dropdown-item" href="/users/business">Business Users</a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <button
                    class="btn btn-primary text-light"  
                    type="submit" 
                    (click)='logout()'>
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </nav>                  
          </div>
        </div>
      </div>
    </div>
  </header>