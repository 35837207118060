import { Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { HttpsService } from '../../services/https.service';
@Component({
  selector: 'app-direct-pay-approve-payouts',
  templateUrl: './direct-pay-approve-payouts.component.html',
  styleUrls: ['./direct-pay-approve-payouts.component.scss']
})
export class DirectPayApprovePayoutsComponent implements OnInit {

  displayedColumns: string[] = ['gig_name','total_amount','brand_name','to_user_mobile','tds','createdAt','order_status','mode']
  dataSource:any
  isLoading:boolean=true;
  clicked=false;
  @ViewChild(MatSort) sort: any;
  @ViewChild(MatPaginator) paginator: any;
  constructor(private httpsService:HttpsService) { }



  ngOnInit(): void {
    this.httpsService.getAllDirectPayOrdersForPayouts().subscribe((res:any)=>{
      this.dataSource = new MatTableDataSource(res.data);
      this.isLoading=false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err=>{
      this.isLoading=false;
    })
  }

  
  applyFilter(text:string){
    this.dataSource.filter = text.trim().toLowerCase()
  }

  test(params:any){
  }


  initiatePayout(id:string){
    this.httpsService.initiateDirectPayOrderPayout({id:id}).subscribe((res:any)=>{
      var temp = this.dataSource.data
      this.dataSource.data = temp.filter((obj:any)=>obj.id!=id)
      this.isLoading=false
      this.clicked=false
    }, err=>{
      let temp = this.dataSource.data
      let index = temp.findIndex((obj:any)=>obj.id==id)
      this.dataSource.data[index].admin_attempted = true
      this.isLoading=false
      this.clicked=false
      if(err.error.data.hasOwnProperty('error')){
        window.alert(err.error.data.error.description)
      }else{
        window.alert(err.error.data.message)
      }
    })
  }

  reAttemptPayout(id:string){
    this.httpsService.reAttemptDirectPayPayout({id:id}).subscribe((res:any)=>{
      let temp = this.dataSource.data
      let index = temp.findIndex((obj:any)=>obj.id==id)
      this.dataSource.data[index].admin_attempted = false
      this.isLoading=false
      this.clicked=false
    }, err=>{
      this.isLoading=false
      this.clicked=false
      if(err.error.data.hasOwnProperty('error')){
        window.alert(err.error.data.error.description)
      }else{
        window.alert(err.error.data.message)
      }
    })
  }

}
