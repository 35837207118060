<section style="margin: 20px 50px;">
  <h1>All Users Information</h1>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.8);
      position: absolute;
      width: 100%;
      height: 50%;
      z-index: 1;
    "
    *ngIf="isLoading"
  >
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <mat-form-field>
    <input
      matInput
      placeholder="Search"
    />
  </mat-form-field>
  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="table mt-4 table-striped"
    >
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>mobile</th>
        <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
      </ng-container>
      <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>firstname</th>
        <td mat-cell *matCellDef="let element">{{ element.firstname }}</td>
      </ng-container>
      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>lastname</th>
        <td mat-cell *matCellDef="let element">{{ element.lastname }}</td>
      </ng-container>
      <ng-container matColumnDef="seconds">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          signed up date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | momentpipe }}
        </td>
      </ng-container>
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>username</th>
        <td mat-cell *matCellDef="let element">{{ element.username }}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>
      <ng-container matColumnDef="total_amount_added">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total amount added to Vouch</th>
        <td mat-cell *matCellDef="let element">{{ element.total_amount_added }}</td>
      </ng-container>
      <ng-container matColumnDef="current_balance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Balance</th>
        <td mat-cell *matCellDef="let element">{{ element.current_balance }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        routerLink="/users/amount/add/{{ row.id }}"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[25, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</section>
