import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timerdate'
})
export class TimerdatePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    var a=''
    if(moment(value).format('DD MMM YYYY hh:mm:ss a')!='Invalid date'){
      a=moment(value).format('DD MMM YYYY hh:mm:ss a')
    }
    return a;
  }

}
