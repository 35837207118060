import { Component, OnInit } from '@angular/core';
import * as moment  from 'moment'
import { HttpsService } from '../services/https.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import {MatDialog} from '@angular/material/dialog';
import { PreviewPopupComponent } from '../preview-popup/preview-popup.component'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  settlementId='';
  createdAt='';
  clicked=false;
  buttonText = 'proceed'
  profileData:any = {};
  settlementData:any[] = [];
  constructor(public dialog: MatDialog,private snackBar:MatSnackBar,private httpsService:HttpsService,private router:Router,private authService:AuthService) { }

  ngOnInit(): void {
    this.httpsService.getProfile().subscribe((res:any)=>{
      this.profileData=res.data
    },err=>{
      // this.snackBar.open(err,"Ok",{duration:10000});
  })
  }


  processSettlement(){
    if(this.settlementId && this.createdAt){
    this.httpsService.updateVouchOrderSettled({settlement_id:this.settlementId,created_at:moment(this.createdAt).format('X')}).subscribe((res:any)=>{
      this.clicked=false
      this.settlementData=res.data
    },err=>{
          this.clicked=false
          // this.snackBar.open(err,"Ok",{duration:10000});
      })
    }
    else{
      this.clicked=false
      // this.snackBar.open('Please Fill all the Fields',"Ok",{duration:10000})
    }
  }

  logout(){
    this.authService.logout().then((result:any) => {
      this.router.navigate(['/'])
    }).catch((err:any) => {
      
    });
  }
}
