import { Component, ViewChild, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';


import { AuthService } from './services/auth.service';
import { TimeoutComponent } from './timeout/timeout.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date;
  title = 'angular-idle-timeout';


  @ViewChild('childModal', { static: false }) childModal:any;
  constructor(private idle: Idle, private keepalive: Keepalive,
    private router: Router,
    public dialog: MatDialog,
    private authService:AuthService) {
    idle.setIdle(28800);
    idle.setTimeout(10);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.logout()
    });
    
    idle.onIdleStart.subscribe(() => {
      this.openDialog()
    });

    this.authService.sharedisLoggedIn.subscribe(result => {
        if(result){
        idle.watch()
        this.timedOut = false;
      }
      else{
      }
    })
  }
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  stay() {
    this.dialog.closeAll()
    this.reset();
  }

  logout() {
    this.dialog.closeAll()
    this.authService.logout().then((result:any) => {
    }).catch((err:any) => {
      
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TimeoutComponent, {
      width: '250px',
      data: {name: 'Nandhi', animal: 'Nandhi'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.logout()
      }else{
        this.stay()
      }
    });
  }
}
