<section style="margin: 20px 50px">
    <h1>Approve Direct Pay Orders</h1>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        width: 100%;
        height: 50%;
        z-index: 1;
      "
      *ngIf="isLoading"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <mat-form-field>
      <input
        (keyup)="applyFilter($any($event.target).value)"
        matInput
        placeholder="Search"
      />
    </mat-form-field>
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="table mt-4 table-striped"
      >
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          created at
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | momentpipe }}
        </td>
      </ng-container>
        <ng-container matColumnDef="gig_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Name</th>
          <td mat-cell *matCellDef="let element">{{ element.gig_name }}</td>
        </ng-container>
        <ng-container matColumnDef="brand_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
          <td mat-cell *matCellDef="let element">{{ element.brand_name }}</td>
        </ng-container>
        <ng-container matColumnDef="to_user_mobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>to_user_mobile</th>
          <td mat-cell *matCellDef="let element">{{ element.to_user_mobile }}</td>
        </ng-container>
        <ng-container matColumnDef="order_status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>order status</th>
          <td mat-cell *matCellDef="let element">{{ element.order_status }}</td>
        </ng-container>
        <ng-container matColumnDef="total_amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
          <td mat-cell *matCellDef="let element">{{ element.total_amount }}</td>
        </ng-container>
        <ng-container matColumnDef="tds">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>tds</th>
          <td mat-cell *matCellDef="let element">{{ element.tds }}</td>
        </ng-container>
        <ng-container matColumnDef="mode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button 
            *ngIf='element.admin_attempted && element.payout_status == "yet_to_process" && element.order_status == "paid" && element.settled == false'
            [disabled]='clicked'
            (click)='clicked=true;reAttemptPayout(element.id)'
            class='btn btn-sm btn-warning fs-8'>Re-Attempt</button>
            <button 
            *ngIf='!element.admin_attempted && element.payout_status == "yet_to_process" && element.order_status == "paid" && element.settled == false'
            (click)='clicked=true;initiatePayout(element.id)'
            [disabled]='clicked'
            class='btn btn-sm btn-success'>Approve</button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[25, 50, 100]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </section>
  