import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpsService } from '../../services/https.service';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
@Component({
  selector: 'app-all-business-users',
  templateUrl: './all-business-users.component.html',
  styleUrls: ['./all-business-users.component.scss']
})
export class AllBusinessUsersComponent implements OnInit {
  displayedColumns: string[] = ['mobile','firstname','lastname','username','email','seconds','total_amount_added','current_balance'];
  dataSource:any
  usersData:any;
  isLoading:boolean=true;
  @ViewChild(MatSort) sort: any;
  @ViewChild(MatPaginator) paginator: any;

  constructor(private httpsService:HttpsService) { }

  ngOnInit(): void {
    this.httpsService.fetchAllBusinessUsers().subscribe((res:any)=>{
      this.usersData=res.data;
      this.usersData.sort((a:any,b:any)=>{return b.createdAt?._seconds - a.createdAt?._seconds});      
      this.dataSource = new MatTableDataSource(this.usersData);
      this.isLoading=false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err=>{
      this.isLoading=false;
    })
  }

}
