<div *ngIf='isLoading' class="text-center" style="margin-top: 15%;">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
  </div>
<div *ngIf='!isLoading' class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
        <div *ngIf='gigData' style="padding: 40px 30px;">
            <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;margin-top: 20px;">
                <img src="{{gigData.buyerData.image}}" alt="Paris" style="width:60px;height: 60px;object-fit: contain;border-radius:100px" *ngIf="gigData.buyerData.image">
                <div *ngIf="!gigData.buyerData.image" style="width:60px;height: 60px;background-color: #F2F2F2;display: flex;justify-content: center;align-items: center;border-radius: 100px;">
                    <p class="mb-0" style="color: #AAA;font-size: 10px;">{{gigData.buyerData.firstname}}</p>
                </div>
                <div style="flex: 0.45;height: 5px;background-color: #F2F2F2;"></div>
                <div style="width: 80px;height: 80px;border-radius: 100px;display: flex;justify-content: center;align-items: center;" [ngStyle]="{'background-color': gigData.gigDetails.order_status=='rejected' || gigData.gigDetails.order_status=='cancelled'?'#D92E0B':'#481380'}">
                    <i *ngIf='gigData.gigDetails.order_status=="rejected" || gigData.gigDetails.order_status=="cancelled"' style="font-size: 50px;color: white;" class="fas fa-check"></i>
                    <i *ngIf='gigData.gigDetails.orderCompletelySettled' class="fas fa-exclamation" style="font-size: 40px;color: white;"></i>
                    <h5 *ngIf='!(gigData.gigDetails.order_status=="rejected" || gigData.gigDetails.order_status=="cancelled") && !gigData.gigDetails.orderCompletelySettled' style="color: white;margin-bottom: 0px;">{{gigData.gigDetails.total_amount | currency:"&#8377;"}}</h5>
                </div>
                <div style="flex: 0.45;height: 5px;background-color: #F2F2F2;"></div>
                <img src="{{gigData.sellerData.image}}" alt="Paris" style="width:60px;height: 60px;object-fit: contain;border-radius:100px" *ngIf="gigData.sellerData.image">
                <div *ngIf="!gigData.sellerData.image" style="width:60px;height: 60px;background-color: #F2F2F2;display: flex;justify-content: center;align-items: center;border-radius: 100px;">
                    <p class="mb-0" style="color: #AAA;font-size: 10px;">{{gigData.sellerData.firstname}}</p>
                </div>
            </div>
            <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
                    <p class="mb-0" style="color: #481380;font-size: 12px;margin-top: -20px;">Buyer</p>
                    <div style="flex: 0.42;height: 5px;"></div>
                    <p class="mb-0" style="color: #481380;font-size: 12px;">Vouch</p>
                    <div style="flex: 0.42;height: 5px;"></div>
                    <p class="mb-0" style="color: #481380;font-size: 12px;margin-top: -20px;">Seller</p>
            </div>
            <div style="border-radius: 20px;border-width: 1px;border-style: solid;border-color: #AAA;padding: 15px 15px;margin-top: 30px;">
                <h3 class="mb-2" style="color: black;">{{gigData.gigDetails.gig_name}}</h3>
                <p class="mb-0" style="font-size: 12px;"> Payment Id:{{gigData.gigDetails.id}}</p>
                <p class="mb-0" style="font-size: 12px;"> Created on {{gigData.gigDetails.createdAt | momentpipe}}</p>
            </div>
            <div style="display:flex;flex-direction:row;justify-content: space-between;margin: 50px 0px 0px 0px;">
                <p style="font-weight: bold;font-size: 15px;">Amount</p>
                <p  style="font-weight: bold;font-size: 15px;">{{gigData.gigDetails.total_amount | currency:"&#8377;"}}</p>
            </div>
            <div style="display:flex;flex-direction:row;justify-content: space-between;margin: 10px 0 25px 0;">
                <p style="font-weight: bold;font-size: 15px;">Order Status</p>
                <p  style="font-weight: bold;font-size: 15px;">{{gigData.gigDetails.order_status}}</p>
            </div>
            <div *ngIf='!gigData.gigDetails.isVirtual'>
                <h4 style="font-weight: bold;font-size: 18px;color: #481380;">Milestone</h4>
                <div *ngFor="let item of gigData.milestoneDetails" style="background-color: #F2F2F2;border-radius: 10px;padding: 20px;margin-bottom: 15px;">
                    <div style="display: flex;flex-direction: row;">
                        <p style="margin-bottom: 0;flex: 0.1;font-weight: bold;">{{item.index}}</p>
                        <div style="flex: 0.8;">
                            <p style="margin-bottom: 0;color: #707070;">{{item.details}}</p>
                            <p style="margin-bottom: 0;margin-top: 10px;font-weight: bold;color: #481380;">{{item.milestone_status}}</p>
                        </div>
                        <p style="margin-bottom: 0;flex: 0.2;color: #464646;">{{item.milestoneAmount | currency:"&#8377;"}}</p>
                    </div>
                </div>
            </div>
      </div>
      </div>
      <div class="col-md-1 col-lg-1 col-xs-0 col-sm-0">
      </div>
      <div class="col-md-5 col-lg-5 col-xs-12 col-sm-12 align-items-center">
        <h1 class="mt-5">Gig Timeline</h1>
          <div *ngFor='let item of gigTimelineData' class="d-flex flex-row">
              <div class="d-flex flex-column align-items-center">
                  <div [ngStyle]="{'background-color': item.color}" style="width: 20px;height: 20px;border-radius: 10px;"></div>
                  <div *ngIf='!item.isDotted' [ngStyle]="{'background-color': item.strokeColor}" style="width: 2px;height: 40px;"></div>
                  <div *ngIf='item.isDotted' style="width: 2px;height: 40px;border-left: dashed 2px #707070;"></div>
              </div>
              <div class="ml-2">
                <p class="mb-1">{{item.message}}</p>
                <small>{{item.date | timerdate}}</small>
              </div>
          </div>
      </div>
      </div>
    </div>