import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpsService } from 'src/app/services/https.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-add-amount',
  templateUrl: './add-amount.component.html',
  styleUrls: ['./add-amount.component.scss'],
})
export class AddAmountComponent implements OnInit {
  enteredAmount = '';
  id: any;
  user_info: any;
  isLoading: boolean = true;
  isAddingAmount: boolean = false;
  enteredBrandName!: string;
  isSavingBrandName = false;
  brands: any;
  constructor(
    private httpsService: HttpsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.httpsService
      .fetchSingleBusinnessUserInfo({ id: this.id })
      .subscribe(
        (res: any) => {
          this.isLoading = false;
          // console.log('***************************************');
          // console.log(res.data.user_info);
          // console.log(res.data.brands);

          this.user_info = res.data.user_info;
          this.brands = res.data.brands;
      this.brands.sort((a:any,b:any)=>{return a.createdAt._seconds - b.createdAt._seconds})
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  addAmount(brandId: string, balanceRecordId: String) {
    console.log();

    if (this.enteredAmount.trim() === '') return alert('Amount cant be empty');
    if (!this.enteredAmount.trim().match(/^[0-9]+$/))
      return alert('Amount Should be an Intezer');
    if (parseInt(this.enteredAmount.trim()) < 0)
      return alert('Amount cant be Negative');
    if (parseInt(this.enteredAmount.trim()) === 0)
      return alert('Amount cant be Zero');
    this.isAddingAmount = true;
    let params = {
      userId: this.id,
      amount: this.enteredAmount,
      bid:brandId,
      id:balanceRecordId,
    };
    this.httpsService.addBusinessUserAmount(params).subscribe(
      (res: any) => {
        this.brands = res.data.brands;
      this.brands.sort((a:any,b:any)=>{return a.createdAt._seconds - b.createdAt._seconds})
        this.isAddingAmount = false;
      },
      (err) => {
        this.isAddingAmount = false;
      }
    );
  }

  addMember() {
    if (this.enteredBrandName.trim() === '')
      return alert('Please Add a brand Name');
    this.isSavingBrandName = true;
    let params = {
      id: this.id,
      brandName: this.enteredBrandName,
    };
    this.httpsService.addNewBrandNameForBusinessUser(params).subscribe(
      (res: any) => {
        this.user_info = { ...this.user_info, ...res.data.updatedAmounts };
        this.enteredBrandName = '';
        // console.log(res.data.brands);
        this.brands = res.data.brands;
      this.brands.sort((a:any,b:any)=>{return a.createdAt._seconds - b.createdAt._seconds})
        this.isSavingBrandName = false;
      },
      (err) => {
        this.isSavingBrandName = false;
      }
    );
  }

  resetEnteredAmount() {
    this.enteredAmount = '';
  }
}
