// export const environment = {
//     firebase: {
//     apiKey: "AIzaSyDXQMcUBRivwPhlPcSRFdNlEPB4N_m2Bqg",
//     authDomain: "vouch-testz.firebaseapp.com",
//     databaseURL: "https://vouch-testz.firebaseio.com",
//     projectId: "vouch-testz",
//     storageBucket: "vouch-testz.appspot.com",
//     messagingSenderId: "923127562999",
//     appId: "1:923127562999:web:d7175c87493b2caabb09f2",
//     measurementId: "G-M756V1G69V",
//   },
//   endpoint:"http://localhost:3000/v1",
//   // endpoint:"https://test.api.iamvouched.com/v1",
//   production: false
// };

export const environment = {
  firebase:{
    apiKey: "AIzaSyDEWSVzqrY16-2x8AuMR1NIAUKdMjvvGAw",
    authDomain: "vouch-temp.firebaseapp.com",
    projectId: "vouch-temp",
    storageBucket: "vouch-temp.appspot.com",
    messagingSenderId: "206209604689",
    appId: "1:206209604689:web:fd862e0aa7db9bccd36c70",
    measurementId: "G-BL6Z3CF9BV"
  },
  endpoint:"https://prod.api.getvouched.co/v1",
  // endpoint:"http://localhost:3000/v1",
  production: true,
};


// export const environment = {
//   firebase:{
//     apiKey: "AIzaSyD-dVDO1aiver2FvoqrREWlq-UtnO2Q8OY",
//     authDomain: "newvouchtest.firebaseapp.com",
//     projectId: "newvouchtest",
//     storageBucket: "newvouchtest.appspot.com",
//     messagingSenderId: "726682252124",
//     appId: "1:726682252124:web:c6ba3aeb556f6eaeeac799",
//     measurementId: "G-LJLKLMWMRK"
//   },
//   // endpoint:"https://prod.api.iamvouched.com/v1",
//   endpoint:"http://localhost:3000/v1",
//   production: true,
// };