import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'daysdifference'
})
export class DaysdifferencePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return moment(new Date(value._seconds * 1000)).add(5,'days').diff(moment(),'days')+1;
  }

}
