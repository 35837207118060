<div class='container'>
    <div class="row">
    <div class="col-md-4 col-lg-4 col-xs-1 col-sm-2">

    </div>
    <div class="col-md-4 col-lg-4 col-xs-10 col-sm-8">
        <div class="d-flex flex-row">
            <div class="d-flex flex-column">
                <div style="width: 20px;height: 20px;color: red;"></div>
                <div style="width: 2px;height: 100px;border-left: solid 1px red;"></div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4 col-xs-11 col-sm-2">

    </div>
    </div>
</div>