import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './authentication.guard';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { HomeComponent } from './home/home.component';
import { TimerlineComponent } from './timerline/timerline.component';

const routes: Routes = [
  {path:'login',loadChildren:()=>import('./auth/auth.module').then(m=>m.AuthModule)},
  {path:'',component:HomeComponent,canActivate:[AuthenticationGuard]},
  {path:'auto-complete',component:AutoCompleteComponent,canActivate:[AuthenticationGuard]},
  {path:'payout',loadChildren:()=>import('./payout/payout.module').then(m=>m.PayoutModule),canActivate:[AuthenticationGuard]},
  {path:'timeline',component:TimerlineComponent,canActivate:[AuthenticationGuard]},
  {path:'users',loadChildren:()=>import('./users/users.module').then(m=>m.UsersModule),canActivate:[AuthenticationGuard]},
  {path:'p2p',loadChildren:()=>import('./p2p/p2p.module').then(m=>m.P2pModule),canActivate:[AuthenticationGuard]},
  {path:'tds',loadChildren:()=>import('./tds/tds.module').then(m=>m.TdsModule),canActivate:[AuthenticationGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}