import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentpipe'
})
export class MomentpipePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {    
    // console.log(value);
    // console.log(new Date(value._seconds * 1000));
    // console.log(moment(value));
    // console.log(moment(value).format('DD MMM YYYY hh:mm:ss a'));
    // console.log(moment(new Date(value)).format('DD MMM YYYY hh:mm:ss a'));
    return moment(new Date(value._seconds * 1000)).format('DD MMM YYYY hh:mm a');
    // return moment(value).format('DD MMM YYYY hh:mm:ss a');
  }

}
