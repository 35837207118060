import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpsService } from 'src/app/services/https.service';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-gig-details',
  templateUrl: './gig-details.component.html',
  styleUrls: ['./gig-details.component.scss']
})
export class GigDetailsComponent implements OnInit {

  pid:any;
  gigData:any;
  gigTimelineData:any;
  isLoading:any=true;
  constructor(private httpsService:HttpsService,private route:ActivatedRoute,private detectRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.pid=this.route.snapshot.params.id;
    this.httpsService.getGigTimer({paymentid:this.pid}).subscribe((res:any)=>{
      this.gigData=res.data
      this.gigTimelineData=res.data.timerArray
      this.detectRef.detectChanges();
      this.isLoading=false;      
    },err=>{
      this.isLoading=false;      
  })
  }
}
