<section id="sec5">
  <div class="container mt-3 pt-3">
    <div class="row flex-row justify-content-between mt-5 mb-5 mr-3 ml-3">
      <h1
        style="
          font-size: 35px;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          font-weight: bold;
        "
      >
        Hi {{ profileData.firstname }}
      </h1>
      <!-- <button
        [disabled]="clicked" 
        class="btn btn-primary text-light"  
        type="submit" 
        (click)='clicked=true;logout()'>Logout</button> -->
    </div>
    <div class="row flex-column">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4">
        <div>
          <form>
            <div>
              <div class="form-group">
                <label>Enter Settlements Id:</label>
                <input
                  name="settlementId"
                  [(ngModel)]="settlementId"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <hr />
                <label>Created At</label>
                <input
                  type="text"
                  class="form-control"
                  name="createdAt"
                  [(ngModel)]="createdAt"
                />
              </div>
            </div>
            <div>
              <button
                [disabled]="clicked"
                class="btn btn-primary text-light"
                type="submit"
                (click)="clicked = true; processSettlement()"
              >
                {{ buttonText }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <h2 *ngIf="settlementData.length != 0" class="mt-4">Milestone Updated<small>(order_settled flag updated to true)</small></h2>
    <table *ngIf="settlementData.length != 0" class="table mt-4 table-striped">
      <thead class="thead-dark">
        <tr>
          <th>Razorpay Payment ID</th>
          <th>Milestone ID</th>
          <th>Gig ID</th>
          <th>Milestone Status</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of settlementData">
          <td>{{ item.payment_id }}</td>
          <td>{{ item.milestone_id }}</td>
          <td>{{ item.milestoneData.paymentRef }}</td>
          <td>{{ item.milestoneData.milestone_status }}</td>
          <td>{{ item.milestoneData.milestoneAmount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
<div id="recaptcha"></div>
