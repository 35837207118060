<section style="margin: 20px 50px">
    <h1>All P2P Payout</h1>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        width: 100%;
        height: 50%;
        z-index: 1;
      "
      *ngIf="isLoading"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <mat-form-field>
      <input
        (keyup)="applyFilter($any($event.target).value)"
        matInput
        placeholder="Search"
      />
    </mat-form-field>
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="table mt-4 table-striped"
      >
        <ng-container matColumnDef="slotStartTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Slot
          </th>
          <td mat-cell *matCellDef="let element" >
            <span class="font-weight-bold">{{ element.slotStartTime | slotPipe }} - {{ element.slotEndTime | slotPipe }}</span><br />
            <span class="fs-7">{{ element.slotStartTime | slotDatePipe }} - {{ element.slotEndTime | slotDatePipe }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="seller_mobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Seller Mobile
          </th>
          <td mat-cell *matCellDef="let element">{{ element.seller_mobile }}</td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Payout Amount
          </th>
          <td mat-cell *matCellDef="let element">
            &#8377; {{ element.amount }}
          </td>
        </ng-container>
        <ng-container matColumnDef="order_ids">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Total Orders
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.order_ids.length}}
            </td>
        </ng-container>
        <ng-container matColumnDef="transfer_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf='element.transfer_status=="FAILED"' class="badge badge-danger">FAILED</span>
              <span *ngIf='element.transfer_status=="INITIATED"' class="badge badge-warning">INITIATED</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="transferMode">
          <th mat-header-cell *matHeaderCellDef>
            Action
          </th>
          <td mat-cell *matCellDef="let element">
            <button 
            *ngIf='element.transfer_status=="FAILED"'
            [disabled]='clicked'
            (click)='clicked=true;initiatePayout(element.id)'
            class='btn btn-sm btn-success'>Reinitiate</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index"
        ></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[25, 50, 100]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </section>
  