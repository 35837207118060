import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { HttpsService } from 'src/app/services/https.service';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  displayedColumns: string[] = ['doc_id','gig_name','mode_processed','to_user_name','total_amount','category','seconds','completed_seconds','order_status'];
  dataSource:any= new MatTableDataSource()
  id:any;
  user_info:any;
  payment_data:any;
  pow_data:any;
  direct_pay_data:any
  isLoading:boolean=true;
  @ViewChild(MatSort) sort: any;
  @ViewChild(MatPaginator) paginator: any;
  index=0;
  tableTitle:string='Total Gigs';
  isMakingBusinessUserLoading:boolean = false;
  enteredPanNumber!: string;
  isSavingPanNumber = false;
  editFirstName = false;
  editLastName = false;
  newfirstName:string;
  newLastName:string;
  isResettingPOWAccount=false;
  constructor(private httpsService:HttpsService,private route:ActivatedRoute,) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.params.id
    this.httpsService.fetchSingleUserInfo({id:this.id}).subscribe((res:any)=>{
      this.dataSource.data = res.data.payment_data;
      this.isLoading=false;
      // this.dataSource.paginator = this.paginator;
      this.user_info=res.data.user_info;
      this.payment_data=res.data.payment_data;
      this.pow_data = res.data.proof_of_work_bene_data;
      this.direct_pay_data = res.data.direct_pay_bene_data;
    }, err=>{
      this.isLoading=false;
    })
  }
  ngAfterViewInit():void{
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(text:string){
    this.dataSource.filter = text.trim().toLowerCase()
  }
  test(index:any){
    this.index=index;
    var temp
    switch (index) {
      case 0:
        this.dataSource.data = this.payment_data;
        this.tableTitle = 'Total Gigs';
        break;
      case 1:
        temp = this.payment_data.filter(({order_status}:any) => order_status === 'completed');
        this.dataSource.data = temp;
        this.tableTitle = 'Total Gigs Completed';
        break;
      case 2:
        temp = this.payment_data.filter(({mode_processed}:any) => mode_processed === 'selling')
        this.dataSource.data = temp;
        this.tableTitle = 'Total Gigs as seller';
        break;
      case 3:
        temp = this.payment_data.filter(({mode_processed,order_status}:any) => (mode_processed === 'selling' && order_status === 'completed'));
        this.dataSource.data = temp;
        this.tableTitle = 'Gigs completed as seller';
        break;
      case 4:
        temp = this.payment_data.filter(({mode_processed,order_status}:any) => (mode_processed === 'selling' && !(['rejected','cancelled','completed'].includes(order_status))));
        this.dataSource.data = temp;
        this.tableTitle = 'Active Gigs as seller';
        break;
      case 5:
        temp = this.payment_data.filter(({mode_processed}:any) => mode_processed === 'buying');
        this.dataSource.data = temp;
        this.tableTitle = 'Total Gigs as buyer';
        break;
      case 6:
        temp = this.payment_data.filter(({mode_processed,order_status}:any) => (mode_processed === 'buying' && order_status === 'completed'));
        this.dataSource.data = temp;
        this.tableTitle = 'Gigs completed as buyer';
        break;
      case 7:
        temp = this.payment_data.filter(({mode_processed,order_status}:any) => (mode_processed === 'buying' && !(['rejected','cancelled','completed'].includes(order_status))));
        this.dataSource.data = temp;
        this.tableTitle = 'Active Gigs as buyer';
        break;
      default:
        break;
    }
  }
  convertToBusinessUser(){
    this.httpsService.makeBusinessUser({id:this.id}).subscribe((res:any)=>{
      this.isMakingBusinessUserLoading=false;
      this.user_info['user_type']='BUSINESS';
    }, err=>{
      this.isMakingBusinessUserLoading=false;
    })
  }

  updatePanDetails(){
    console.log(this.enteredPanNumber);
    if (this.enteredPanNumber.trim() === '')
    return alert('Please enter the pan number');
  this.isSavingPanNumber = true;
  let params = {
    id: this.id,
    panNumber: this.enteredPanNumber,
  };
  this.httpsService.savePanDetials(params).subscribe(
    (res: any) => {
      this.user_info = { ...this.user_info, pan_number: this.enteredPanNumber };
      this.enteredPanNumber = '';
      this.isSavingPanNumber = false;
      window.alert('updated Pan Details')
    },
    (err:any) => {
      this.isSavingPanNumber = false;
      window.alert('Something went wrong')
    }
  );
  }


  onFirstNameInputFocusOut(){
    if(this.newfirstName.trim()){
      this.httpsService.editUserDetials({
      uid:this.id,
      key:'firstname',
      value:this.newfirstName
    }).subscribe((res:any)=>{
      this.user_info.firstname = this.newfirstName;
    }, err=>{
    })
  }
  }

  onLastNameInputFocusOut(){
    if(this.newLastName.trim()){ 
      this.httpsService.editUserDetials({
      uid:this.id,
      key:'lastname',
      value:this.newLastName
    }).subscribe((res:any)=>{
      this.user_info.lastname = this.newLastName;
    }, err=>{
    })
  }
  }


  resetAccountDetails(){
    this.httpsService.resetUserBeneficiaryDetails({
      uid:this.id,
      bid:this.pow_data.id
    }).subscribe((res:any)=>{
      this.pow_data = null
      this.isResettingPOWAccount =true
    }, err=>{
      this.isResettingPOWAccount =false
    })
  }
}
