import { Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { HttpsService } from '../../services/https.service';
@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent implements OnInit {
  displayedColumns: string[] = ['mobile','firstname','lastname','username','email','account_type','Ref','seconds']
  dataSource:any
  usersData:any;
  isLoading:boolean=true;
  @ViewChild(MatSort) sort: any;
  @ViewChild(MatPaginator) paginator: any;
  constructor(private httpsService:HttpsService) { }



  ngOnInit(): void {
    this.httpsService.fetchAllUsersInfo().subscribe((res:any)=>{
      this.usersData=res.data;
      this.usersData.sort((a:any,b:any)=>{return b.createdAt?._seconds - a.createdAt?._seconds});      
      this.dataSource = new MatTableDataSource(this.usersData);
      this.isLoading=false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err=>{
      this.isLoading=false;
    })
  }

  
  applyFilter(text:string){
    this.dataSource.filter = text.trim().toLowerCase()
  }

  test(params:any){
  }
}
