import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpsService } from 'src/app/services/https.service';

@Component({
  selector: 'app-pending-payouts',
  templateUrl: './pending-payouts.component.html',
  styleUrls: ['./pending-payouts.component.scss']
})
export class PendingPayoutsComponent implements OnInit {
  isLoading:boolean = true;
  pendingPayouts:any[] = [];
  clicked:boolean = false;
  constructor(private httpsService:HttpsService,private router:Router) { }

  ngOnInit(): void {
    this.httpsService.getPayoutStatus().subscribe((res:any)=>{
      this.pendingPayouts=res.data      
      
      this.isLoading=false
    }, err=>{
      this.isLoading=false
    })
  }

  initiatePayout(id:string){
    this.httpsService.reinitiateGigPayout({milestoneId:id}).subscribe((res:any)=>{
      var objIndex = this.pendingPayouts.findIndex((obj => obj.milestone_id == id));
      this.pendingPayouts[objIndex].milestoneData.payout_status='initiated'
      // this.pendingPayouts.splice(objIndex,objIndex)
      this.isLoading=false
      this.clicked=false
    }, err=>{
      let temp = this.pendingPayouts
      let index = temp.findIndex(obj=>obj.milestone_id==id)
      this.pendingPayouts[index].milestoneData.admin_attempted = true
      this.isLoading=false
      this.clicked=false
      if(err.error.data.hasOwnProperty('error')){
        window.alert(err.error.data.error.description)
      }else{
        window.alert(err.error.data.message)
      }
    })
  }

  reAttemptPayout(id:string){
    this.httpsService.reAttemptPayout({id:id}).subscribe((res:any)=>{
      let temp = this.pendingPayouts
      let index = temp.findIndex(obj=>obj.milestone_id==id)
      this.pendingPayouts[index].milestoneData.admin_attempted = false
      this.isLoading=false
      this.clicked=false
    }, err=>{
      this.isLoading=false
      this.clicked=false
      if(err.error.data.hasOwnProperty('error')){
        window.alert(err.error.data.error.description)
      }else{
        window.alert(err.error.data.message)
      }
    })
  }
  
}
