import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { MomentpipePipe } from '../helpers/pipes/momentpipe.pipe';
import { HttpsService } from '../services/https.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-preview-popup',
  templateUrl: './preview-popup.component.html',
  styleUrls: ['./preview-popup.component.scss'],
  // pipes:[MomentpipePipe]
})
export class PreviewPopupComponent implements OnInit {

  settlemtId:any
  createdAt:any
  paymentData:any[] = [];
  previewData:any[] = [];
  gigTimelineData:any[] = []
  selectedMilestoneData:any = {}
  selectedFlag=false
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<PreviewPopupComponent>,private httpsService:HttpsService) {
   }

  ngOnInit(): void {
    this.settlemtId=this.data.settlement_id
    this.createdAt=this.data.created_at
    this.paymentData=this.data.data.payment_data
    this.previewData=this.data.data.mile_req
  }

  close(){
    this.dialogRef.close(false)
  }
  preview(item:any){
    this.httpsService.getGigTimer({paymentid:item.gigId}).subscribe((res:any)=>{
      this.selectedFlag=true
      this.selectedMilestoneData=item
      this.gigTimelineData=res.data
    },err=>{
      // console.log(err);
  })
  }

}
