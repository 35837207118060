import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { P2ppayouttimeslotsService } from 'src/app/services/p2ppayouttimeslots.service';
import { HttpsService } from '../../services/https.service';

@Component({
  selector: 'app-pending-p2p-payout',
  templateUrl: './pending-p2p-payout.component.html',
  styleUrls: ['./pending-p2p-payout.component.scss'],
})
export class PendingP2pPayoutComponent implements OnInit {
  displayedColumns: string[] = [
    'seller_mobile',
    'amount',
    'order_ids',
    'slotStartTime',
    'transfer_status',
    'transferMode',
  ];
  dataSource: any;
  payoutsData: any;
  isLoading: boolean = true;
  clicked: boolean = false;
  @ViewChild(MatSort) sort: any;
  @ViewChild(MatPaginator) paginator: any;
  constructor(
    private httpsService: HttpsService,
    private p2pDataService: P2ppayouttimeslotsService
  ) {}

  ngOnInit(): void {
    this.httpsService.getP2PPayoutStatus().subscribe(
      (res: any) => {
        // console.log(res.data);
        this.payoutsData = res.data;
        this.dataSource = new MatTableDataSource(this.payoutsData);
        this.isLoading = false;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  applyFilter(text: string) {
    this.dataSource.filter = text.trim().toLowerCase();
  }

  test(params: any) {}

  initiatePayout(id: any) {
    const payoutInfo = this.payoutsData.find((item: any) => item.id == id);
    this.httpsService.reinitiateP2PPayout(payoutInfo).subscribe(
      (res: any) => {
        var temp = this.payoutsData;
        this.payoutsData = temp.map((obj: any) => {
          if (obj.id != id) return obj;
          else return {...obj,transfer_status:'INITIATED'}
        });
        console.log(this.payoutsData);
        
        this.dataSource.data = this.payoutsData;
        this.isLoading = false;
        this.clicked = false;
      },
      (err) => {
        this.isLoading = false;
        this.clicked = false;
        if (err.error.data.message) {
          window.alert(err.error.data.message);
        }
        if (err.error.data.error.description) {
          window.alert(err.error.data.error.description);
        } else {
          window.alert(err.error.data.message);
        }
      }
    );
  }
}
