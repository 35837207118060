import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit {

  isLoggedIn:boolean=false;
  constructor(
    private router:Router,
    private authService:AuthService
    ) { }

  ngOnInit(): void {
    this.authService.sharedisLoggedIn.subscribe(isLoggedIn => {
      console.log(isLoggedIn);
      
      this.isLoggedIn = isLoggedIn
    })
  }

  logout(){
    this.authService.logout().then((result:any) => {
      this.router.navigate(['/login'])
    }).catch((err:any) => {
      
    });
  }

}
