import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import * as CryptoJS from "crypto-js";
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private authService:AuthService,private router:Router) {
    
  }
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   console.log('auth Guard called');    
  //   this.authService.isLoggesIn().then((result) => {
  //     if(result){
  //       return true;
  //     }
  //     else{
  //       this.router.navigate(['/']);
  //       return false;
  //     }
  //   }).catch((err) => {
  //     return false
  //   });
  //   return true;
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('auth Guard called');    
    this.authService.isLoggesIn().then((result) => {
      if(result){        
        var token=sessionStorage.getItem('loggedIn')
        if(token){
          var bytes  = CryptoJS.AES.decrypt(token, 'dash@secret#1@2$3');
          var decryptedData = bytes.toString(CryptoJS.enc.Utf8)
          if(decryptedData==result){
            // console.log('Match Found');
            return true;
          }else{
            this.authService.logout().then((result:any) => {
              
            }).catch((err:any) => {
              
            });
            return false;
          }
        }else{
          this.authService.logout().then((result:any) => {
              
          }).catch((err:any) => {
            
          });
          return false;
        }
      }
      else{
        this.router.navigate(['/login']);
        return false;
      }
    }).catch((err) => {
      // console.log(err);
      
      return false
    });
    return true;
  }


}
