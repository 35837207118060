<div class="container-fluid pt-5" style="height: 100vh">
  <div class="container">
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f0f0f0;
        position: absolute;
        width: 80%;
        margin: -10px 0 0 -10px;
        height: 100%;
        z-index: 1;
      "
      *ngIf="isLoading"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <section>
      <div class="row">
        <div class="col-xl-4 col-sm-6 col-12">
          <div
            class="bg-white shadow-sm"
            style="border-radius: 20px; padding: 40px 30px"
          >
            <h1 style="font-weight: bold; font-size: 25px; margin-bottom: 35px">
              {{ user_info.firstname }} {{ user_info.lastname }}
            </h1>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Mobile</p>
              <p>{{ user_info.mobile }}</p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Username</p>
              <p>{{ user_info.username }}</p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Email</p>
              <p style="font-size: 12px">{{ user_info.email }}</p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Account Type</p>
              <p>{{ user_info.account_type }}</p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">User Type</p>
              <p>Business</p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Referral code</p>
              <p>
                {{ user_info.referral_code ? user_info.referral_code : "--" }}
              </p>
            </div>
            <div class="usersGeneralInfo">
              <p class="font-weight-bold">Signed up date</p>
              <p>{{ user_info.createdAt | momentpipe }}</p>
            </div>
          </div>
        </div>
        <div class="col-xl-8 col-sm-6 col-12">
          <div id="accordion">
            <div class="card" *ngFor="let item of brands" (click)="resetEnteredAmount()">
              <div class="card-header">
                <a class="card-link btn" data-toggle="collapse" href='{{"#A"+item.index}}'>
                  {{item.name}}
                </a>
              </div>
              <div id='{{"A"+item.index}}' class="collapse" data-parent="#accordion">
                <div class="card-body h-auto">
                  <div class="row">
                    <div class="col-xl-4 col-sm-6 col-12">
                      <div class="card shadow-sm mt-3">
                        <div class="card-content">
                          <div class="card-body">
                            <span class="cardTitle">Current Balance</span>
                            <h3 class="cardValue">
                              {{ item.current_balance }}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-sm-6 col-12">
                      <div class="card mt-3">
                        <div class="card-content">
                          <div class="card-body">
                            <span class="cardTitle">Total amount added to Vouch</span>
                            <h3 class="cardValue">
                              {{ item.total_amount_added }}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-8 col-sm-6 col-12 p-0">
                    <div class="input-group mt-4 mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">&#8377;</span>
                      </div>
                      <input
                        name="enteredAmount"
                        [(ngModel)]="enteredAmount"
                        type="text"
                        class="form-control"
                        placeholder="Add More Amount"
                      />
                      <button
                        [disabled]="isAddingAmount"
                        (click)="addAmount(item.bid,item.id)"
                        class="btn btn-success ml-2"
                      >
                        Add Amount
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>          
          </div>
          <button
            type="button"
            class="btn btn-info mt-2"
            data-toggle="collapse"
            data-target="#demo"
          >
            Add Sub Brand
          </button>
          <div id="demo" class="collapse col-xl-8 col-sm-6 col-12 p-0">
            <div class="input-group mt-4 mb-3">
              <input
                name="enteredBrandName"
                [(ngModel)]="enteredBrandName"
                type="text"
                class="form-control"
                placeholder="Please Enter brand Name"
              />
              <button
                [disabled]="isSavingBrandName"
                (click)="addMember()"
                class="btn btn-success ml-2"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>


